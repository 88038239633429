import React, { useState } from "react";
import { Modal, TextField } from "@mui/material";
import { format } from "date-fns";
import Title from "components/ui/Title";
import ActionButtons from "components/ui/ActionButtons";

const RejectContractModal = ({
  isOpen,
  onClose,
  onConfirm,
  creatorFirstName,
  creatorLastName,
  createdOn,
}) => {
  const [rejectComments, setRejectComments] = useState("");

  const handleRejectConfirm = () => {
    if (rejectComments) {
      onConfirm(rejectComments);
    } else {
      alert("Review comment cannot be empty");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="reject-contract-modal"
      aria-describedby="modal-to-collect-reject-comment"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: "white",
          padding: "20px",
          boxShadow: 12,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: 390,
        }}
      >
        <div>
          <Title>Reject Contract</Title>
          <div style={{ marginBottom: "24px" }}>
            <p>
              <strong>Created By:&nbsp;</strong>
              {`${creatorFirstName} ${creatorLastName}`}
            </p>
            <p>
              <strong>Created On:&nbsp;</strong>
              {createdOn && format(new Date(createdOn), "dd/MM/yyyy HH:mm")}
            </p>
          </div>
          <TextField
            label="Review Comment"
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            value={rejectComments}
            onChange={(e) => setRejectComments(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
        </div>

        <ActionButtons
          onCloseClick={onClose}
          onActionClick={handleRejectConfirm}
          actionText="Confirm"
          actionIcon={null}
          actionColor="secondary"
        />
      </div>
    </Modal>
  );
};

export default RejectContractModal;
