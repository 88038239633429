import React from "react";
import { Grid, Button, Tooltip } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

const Toolbar = ({
  toolTip = "",
  onAddClick = () => {},
  showAddButton = true,
}) => {
  return (
    <GridToolbarContainer>
      <Grid container item xs>
        {/* default buttons */}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </Grid>

      {showAddButton && (
        <Tooltip title={toolTip}>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              padding: "0",
              marginTop: "5px",
              marginRight: "10px",
              minWidth: "unset",
              "&:hover": {
                backgroundColor: "success.dark",
              },
              "& .MuiButton-startIcon": {
                margin: "auto",
              },
            }}
            startIcon={<AddIcon />}
            onClick={onAddClick}
          ></Button>
        </Tooltip>
      )}
    </GridToolbarContainer>
  );
};

export default Toolbar;
