import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import {
  PostAdd as LibraryAddIcon,
  FolderOpen as FolderOpenIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import useStore from "hooks/useStore";
import Toolbar from "components/grid/Toolbar";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import PageLayout from "components/ui/PageLayout";
import ActionTooltip from "components/ui/ActionTooltip";

const ProjectsList = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const selectedCompany = useStore((state) => state.selectedCompany);
  const isUserAdministrator = useStore((state) => state.isUserAdministrator)();
  const hasContractsDirectorPermissionsInActiveCompany = useStore(
    (state) => state.hasContractsDirectorPermissionsInActiveCompany
  )();
  const canEdit =
    isUserAdministrator || hasContractsDirectorPermissionsInActiveCompany;

  const { data } = useResourceQuery(
    [`companies/${selectedCompany}/projects`, ["projects", selectedCompany]],
    !!selectedCompany
  );
  const { mutateAsync: removeProject } = useResourceMutation(
    "projects",
    MUTATION_TYPES.remove
  );
  const setOpenModal = useStore((state) => state.setOpenModal);
  const columns = [
    {
      field: "contractualReferenceNumber",
      headerName: "Contractual Reference Number",
      width: 210,
    },
    { field: "contractualName", headerName: "Contractual Name", flex: 1 },
    {
      field: "juristicNameOfEmployerClient",
      headerName: "Employer Client's Juristic Name",
      flex: 1,
    },
    {
      field: "physicalAddressOfEmployerClient",
      headerName: "Employer Client's Address",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Actions",
      align: "right",
      width: 190,
      renderCell: (params) => (
        <>
          {canEdit && (
            <ActionTooltip
              title="Assign Templates"
              IconComponent={LibraryAddIcon}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(
                  { projectId: params.row.id, companyId: selectedCompany },
                  "projects/AssignTemplatesToProject"
                );
              }}
            />
          )}
          {canEdit && (
            <ActionTooltip
              title="Assign User Permissions"
              IconComponent={PeopleIcon}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(
                  { projectId: params.row.id, companyId: selectedCompany },
                  "projects/AssignUserPermissions"
                );
              }}
            />
          )}
          <ActionTooltip
            title="Manage sub-projects"
            IconComponent={FolderOpenIcon}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/projects/${params.row.id}/subprojects`);
            }}
          />
          {canEdit && (
            <ActionTooltip
              title="Edit project"
              IconComponent={EditIcon}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal({ row: params.row, companyId: selectedCompany });
              }}
            />
          )}
          {canEdit && (
            <ActionTooltip
              title="Delete project"
              IconComponent={DeleteIcon}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await confirm({
                    description:
                      "Are you sure you want to delete this project?",
                  });
                  removeProject(params.row.id);
                } catch {}
              }}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {data && (
        <PageLayout title="Manage projects">
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{
              toolbar: {
                toolTip: "Add project",
                onAddClick: () => {
                  setOpenModal({ companyId: selectedCompany });
                },
                showAddButton: canEdit,
              },
            }}
            checkboxSelection
          />
        </PageLayout>
      )}
    </>
  );
};

export default ProjectsList;
