import Tooltip from "@mui/material/Tooltip";

const defaultSx = { ml: 1.5, cursor: "pointer" };

const ActionTooltip = ({ title, IconComponent, sx, onClick }) => (
  <Tooltip title={title}>
    <IconComponent
      sx={{ ...defaultSx, ...sx }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    />
  </Tooltip>
);

export default ActionTooltip;
