import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import useStore from "hooks/useStore";
import Toolbar from "components/grid/Toolbar";
import ActionTooltip from "components/ui/ActionTooltip";
import PageLayout from "components/ui/PageLayout";

const withTemplateType = (templateType) => {
  return () => {
    const selectedCompany = useStore((state) => state.selectedCompany);
    const headerTitle = templateType === "tender" ? "tenders" : "contracts";
    const { data } = useResourceQuery([
      `companies/${selectedCompany}/contracts?templateType=${templateType}`,
      ["contracts", selectedCompany, templateType],
    ]);
    const setOpenModal = useStore((state) => state.setOpenModal);

    const columns = [
      { field: "projectName", headerName: "Project Name", flex: 1 },
      { field: "subprojectName", headerName: "Subproject Name", flex: 1 },
      { field: "templateName", headerName: "Template", flex: 1 },
      {
        field: "createdOn",
        headerName: "Created On",
        flex: 1,
        valueGetter: (params) => {
          const date = new Date(params.row.createdOn);
          return format(date, "dd/MM/yyyy HH:mm");
        },
      },
      {
        field: "createdBy",
        headerName: "Created By",
        flex: 1,
        valueGetter: (params) => {
          return `${params.row.creatorFirstName} ${params.row.creatorLastName}`;
        },
      },
      {
        field: "reviewStatus",
        headerName: "Review Status",
        flex: 1,
        valueGetter: (params) => {
          const {
            reviewStatus,
            creatorFirstName,
            creatorLastName,
            reviewerFirstName,
            reviewerLastName,
          } = params.row;
          if (reviewStatus === "Submitted") {
            return `${reviewStatus} by ${creatorFirstName || "Unknown"} ${
              creatorLastName || ""
            }`;
          }
          if (reviewStatus === "Approved" || reviewStatus === "Rejected") {
            return `${reviewStatus} by ${reviewerFirstName || "Unknown"} ${
              reviewerLastName || ""
            }`;
          }
          return reviewStatus;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        align: "right",
        width: 150,
        renderCell: (params) => (
          <>
            {params.row.reviewStatus === "Rejected" && (
              <>
                <ActionTooltip
                  title="Edit template questions"
                  IconComponent={EditIcon}
                  onClick={() => {
                    setOpenModal(
                      {
                        contractId: params.row.contractId,
                        templateId: params.row.templateId,
                      },
                      "contracts/Edit/EditContract",
                      true
                    );
                  }}
                />
              </>
            )}
            {params.row.reviewStatus !== "None" && (
              <>
                <ActionTooltip
                  title="Show review comments"
                  IconComponent={ChatBubbleOutlineIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(
                      {
                        contractId: params.row.contractId,
                        projectName: params.row.projectName,
                        subprojectName: params.row.subprojectName,
                        templateName: params.row.templateName,
                        createdOn: params.row.createdOn,
                      },
                      "contracts/ReviewComments"
                    );
                  }}
                />
              </>
            )}
            <ActionTooltip
              title="Preview Contract"
              IconComponent={VisibilityIcon}
              onClick={() => {
                setOpenModal(
                  {
                    contractId: params.row.contractId,
                  },
                  "contracts/Preview"
                );
              }}
            />
          </>
        ),
      },
    ];

    return (
      <>
        {data && (
          <PageLayout title={`Manage ${headerTitle}`}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              slots={{ toolbar: Toolbar }}
              slotProps={{
                toolbar: {
                  toolTip: "Add Contract",
                  onAddClick: () => {
                    setOpenModal(
                      {
                        companyId: selectedCompany,
                        templateType,
                      },
                      "contracts/Create/CreateContract"
                    );
                  },
                },
              }}
              getRowId={(row) => row.contractId}
              checkboxSelection
            />
          </PageLayout>
        )}
      </>
    );
  };
};

export default withTemplateType;
