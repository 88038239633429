import React from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ProjectsIcon from "@mui/icons-material/Construction";
import DocumentsIcon from "@mui/icons-material/Description";
import ReviewCheckIcon from "@mui/icons-material/Approval";
import useStore from "hooks/useStore";

const CompanyMenu = () => {
  const navigate = useNavigate();
  const isUserAdministrator = useStore((state) => state.isUserAdministrator)();
  const hasContractsApproverPermissionsInActiveCompany = useStore(
    (state) => state.hasContractsApproverPermissionsInActiveCompany
  )();
  const hasContractsCompilerPermissionsInActiveCompany = useStore(
    (state) => state.hasContractsCompilerPermissionsInActiveCompany
  )();
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Company
      </ListSubheader>
      <ListItemButton onClick={() => navigate(`/projects`)}>
        <ListItemIcon>
          <ProjectsIcon />
        </ListItemIcon>
        <ListItemText primary="Manage projects" />
      </ListItemButton>
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton onClick={() => navigate(`/tenders`)}>
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Tenders" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton onClick={() => navigate(`/contracts`)}>
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Contracts" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsCompilerPermissionsInActiveCompany) && (
        <ListItemButton onClick={() => navigate(`/others`)}>
          <ListItemIcon>
            <DocumentsIcon />
          </ListItemIcon>
          <ListItemText primary="Others" />
        </ListItemButton>
      )}
      {(isUserAdministrator ||
        hasContractsApproverPermissionsInActiveCompany) && (
        <ListItemButton onClick={() => navigate(`/reviews`)}>
          <ListItemIcon>
            <ReviewCheckIcon />
          </ListItemIcon>
          <ListItemText primary="Reviews" />
        </ListItemButton>
      )}
    </React.Fragment>
  );
};

export default CompanyMenu;
