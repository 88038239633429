import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import Toolbar from "components/grid/Toolbar";
import Title from "components/ui/Title";
import useStore from "hooks/useStore";

const ManageUsers = () => {
  const confirm = useConfirm();
  const { id: companyId } = useParams();
  const { data: companyData } = useResourceQuery([
    `companies/${companyId}`,
    "company",
  ]);
  const { data: usersData } = useResourceQuery([
    `companies/${companyId}/users`,
    "users",
  ]);
  const setOpenModal = useStore((state) => state.setOpenModal);
  const { mutateAsync: removeUser } = useResourceMutation(
    "users",
    MUTATION_TYPES.remove
  );

  const columns = [
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      align: "right",
      renderCell: (params) => (
        <>
          <Tooltip title="Edit user">
            <EditIcon
              sx={{ cursor: "pointer", mr: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal({ row: params.row, companyId });
              }}
            />
          </Tooltip>
          <Tooltip title="Delete user">
            <DeleteIcon
              sx={{ cursor: "pointer" }}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await confirm({
                    description: "Are you sure you want to delete this user?",
                  });
                  removeUser(params.row.id);
                } catch {}
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      {usersData && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="start"
          justifyContent="start"
          style={{ minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                outline: "none !important",
              },
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/companies">
                Companies
              </Link>
              <Typography color="textPrimary">
                {companyData?.registeredName}
              </Typography>
            </Breadcrumbs>
            <Title>Manage Users</Title>
            <Paper style={{ height: 600, width: "calc(100vw - 290px)" }}>
              <DataGrid
                rows={usersData}
                columns={columns}
                pageSize={5}
                slots={{
                  toolbar: Toolbar,
                }}
                slotProps={{
                  toolbar: {
                    toolTip: "Add user",
                    onAddClick: () => {
                      setOpenModal({ companyId });
                    },
                  },
                }}
                checkboxSelection
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ManageUsers;
