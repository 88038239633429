import React from "react";
import { Modal } from "@mui/material";
import { format } from "date-fns";
import Title from "components/ui/Title";
import ActionButtons from "components/ui/ActionButtons";

const ApproveContractModal = ({
  isOpen,
  onClose,
  onConfirm,
  creatorFirstName,
  creatorLastName,
  createdOn,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="approve-contract-modal"
      aria-describedby="modal-to-confirm-approval"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: "white",
          padding: "20px",
          boxShadow: 12,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: 240,
        }}
      >
        <Title>Approve Contract</Title>
        <div style={{ marginBottom: "24px" }}>
          <p>
            <strong>Created By:&nbsp;</strong>
            {`${creatorFirstName} ${creatorLastName}`}
          </p>
          <p>
            <strong>Created On:&nbsp;</strong>
            {createdOn && format(new Date(createdOn), "dd/MM/yyyy HH:mm")}
          </p>
        </div>
        <ActionButtons
          onCloseClick={onClose}
          onActionClick={onConfirm}
          actionText="Confirm"
          actionIcon={null}
        />
      </div>
    </Modal>
  );
};

export default ApproveContractModal;
