import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetFailed, setResetFailed] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const { mutateAsync: resetPassword } = useResourceMutation(
    "auth/reset-password",
    MUTATION_TYPES.post
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      resetPassword(
        { token, password },
        {
          onSuccess: () => {
            setResetFailed(false);
            setResetSuccess(true);
          },
          onError: (ex) => {
            console.log(ex);
            setResetFailed(true);
            setResetSuccess(false);
          },
        }
      );
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const passwordsMatch = password === confirmPassword;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Reset Password
      </Typography>
      <TextField
        fullWidth
        label="New Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText />
      <TextField
        fullWidth
        label="Confirm Password"
        variant="outlined"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText error={!passwordsMatch && confirmPassword !== ""}>
        {!passwordsMatch && confirmPassword !== ""
          ? "Passwords do not match."
          : " "}
      </FormHelperText>
      {resetFailed && (
        <FormHelperText error>
          The password reset was unsuccessful.
        </FormHelperText>
      )}
      <Button
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        fullWidth
        disabled={!passwordsMatch}
        style={{ marginTop: "1rem" }}
      >
        Submit
      </Button>
      <Link
        href="/login"
        variant="body2"
        style={{ float: "right", marginTop: "10px" }}
      >
        Back to Login
      </Link>
      <Snackbar
        open={resetSuccess}
        autoHideDuration={6000}
        onClose={() => setResetSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" style={{ minWidth: "500px" }}>
          The password reset was successful.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
