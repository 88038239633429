import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import useStore from "hooks/useStore";

const useApi = ({ baseURL, queryParameters = [], ...defaultApiConfig }) => {
  const navigate = useNavigate();
  const clearLoginDetails = useStore((state) => state.clearLoginDetails);
  const getAccessToken = useStore((state) => state.getAccessToken);
  const setAccessToken = useStore((state) => state.setAccessToken);
  const getRefreshToken = useStore((state) => state.getRefreshToken);
  const setTokens = useStore((state) => state.setTokens);
  const axiosInstance = axios.create();
  axiosInstance.interceptors.response.use(({ data }) => data);

  return useMemo(() => {
    const isExpired = (accessToken) =>
      new Date((jwt_decode(accessToken)?.exp || 0) * 1000) < new Date();

    const refreshAccessToken = async () => {
      const refreshToken = getRefreshToken();
      try {
        const newTokens = await axiosInstance({
          method: "post",
          headers: { "Content-Type": "application/json" },
          baseURL,
          data: JSON.stringify({ refreshToken }),
          url: "/auth/refresh-token",
        });
        if (newTokens?.accessToken && newTokens?.refreshToken)
          setTokens(newTokens?.accessToken, newTokens?.refreshToken);
        return newTokens?.accessToken;
      } catch (error) {
        console.log("Refresh error: ", error);
        clearLoginDetails();
        navigate("/login", { replace: true });
      }
    };
    const getFreshAccessToken = async () => {
      const accessToken = getAccessToken();
      if (accessToken && isExpired(accessToken)) {
        return await refreshAccessToken();
      }
      return accessToken;
    };
    const getFilterExpression = () => {
      const filterStrings = queryParameters.map((filter) => {
        return Object.keys(filter).map(
          (key) => filter[key] && `filter[${key}]=${filter[key]}`
        );
      });
      return filterStrings.join("&");
    };
    const filterExpression = getFilterExpression();
    const request = async (url, config = {}) => {
      const accessToken = await getFreshAccessToken();
      return await axiosInstance({
        ...defaultApiConfig,
        ...config,
        headers: accessToken
          ? { Authorization: `Bearer ${accessToken}`, ...config.headers }
          : { ...config.headers },
        baseURL,
        url: filterExpression
          ? `${url}${url.includes("?") ? "&" : "?"}${filterExpression}`
          : url,
        responseType: config.responseType || "json", // Add the responseType to the axios config
      });
    };

    const requestByMethodAndData =
      (method) =>
      (url, apiConfig) =>
      (data, isFormData = false, requestConfig) =>
        request(url, {
          ...apiConfig,
          ...requestConfig,
          method,
          ...(isFormData && { formData: data }),
          ...(!isFormData && { data }),
        });

    const requestByMethod = (method) => (url, apiConfig) => () => {
      return request(url, { ...apiConfig, method });
    };

    return {
      get: requestByMethod("get"),
      post: requestByMethodAndData("post"),
      put: requestByMethodAndData("put"),
      patch: requestByMethodAndData("patch"),
      remove: requestByMethodAndData("delete"),
      filterExpression,
    };
  }, [
    defaultApiConfig,
    baseURL,
    queryParameters,
    getAccessToken,
    setAccessToken,
    axiosInstance,
  ]);
};

export default useApi;
