import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  InputAdornment,
  Link,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import useStore from "hooks/useStore";
import { useSnackbar } from "notistack";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const clearModal = useStore((state) => state.clearModal);
  const setLoginDetails = useStore((state) => state.setLoginDetails);
  const isUserAdministrator = useStore((state) => state.isUserAdministrator);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: loginUser } = useResourceMutation(
    "auth/login",
    MUTATION_TYPES.post
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(
      { email, password },
      {
        onSuccess: ({ accessToken, refreshToken }) => {
          clearModal();
          setLoginDetails(email, accessToken, refreshToken);
          setLoginFailed(false);
          navigate(isUserAdministrator() ? "/companies" : "/projects");
        },
        onError: () => {
          setLoginFailed(true);
          enqueueSnackbar("Login Failed", { variant: "error" });
        },
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Login
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyUp={handleKeyPress}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {loginFailed && (
        <FormHelperText error>
          Invalid username or incorrect password entered.
        </FormHelperText>
      )}
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText />
      <Button
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        fullWidth
      >
        Login
      </Button>
      <Link
        href="/forgot-password"
        variant="body2"
        style={{ float: "right", marginTop: "10px" }}
      >
        Forgot password?
      </Link>
    </>
  );
};

export default Login;
