import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Title from "components/ui/Title";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function CreateForSection() {
  const [nameValuePairs, setNameValuePairs] = useState([
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
  ]);
  const [templateId, setTemplateId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [sections, setSections] = useState([]);
  const { data: templates } = useResourceQuery("templates");
  const { data: sectionsData } = useResourceQuery(
    `templates/${templateId}/sections`,
    templateId !== ""
  );
  const { mutateAsync: createPdf } = useResourceMutation(
    `template-sections/${sectionId}/generate-pdf`,
    MUTATION_TYPES.post,
    { responseType: "blob" }
  );

  useEffect(() => {
    if (sectionsData) {
      setSections(sectionsData);
      setSectionId("");
    }
  }, [sectionsData]);

  const handleChange = (index, field, value) => {
    const updatedNameValuePairs = [...nameValuePairs];
    updatedNameValuePairs[index][field] = value;
    setNameValuePairs(updatedNameValuePairs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = nameValuePairs.reduce((acc, pair) => {
      if (pair.name) {
        acc[pair.name] = pair.value;
      }
      return acc;
    }, {});

    const pdfData = await createPdf(data);

    if (pdfData) {
      //const pdfBlob = await response.blob();
      const url = URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.download = "section_pdf.pdf";
      link.click();
    } else {
      alert("Error generating PDF");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Generate PDF for section</Title>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              labelId="template-select-label"
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              displayEmpty
              textFieldProps={{
                label: "Select Template",
                InputLabelProps: templateId !== "" ? { shrink: true } : {},
              }}
            >
              <MenuItem value="" disabled>
                Select Template
              </MenuItem>
              {templates?.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {templateId && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select
                labelId="section-select-label"
                value={sectionId}
                onChange={(e) => setSectionId(e.target.value)}
                displayEmpty
                textFieldProps={{
                  label: "Select Section",
                  InputLabelProps: sectionId !== "" ? { shrink: true } : {},
                }}
              >
                <MenuItem value="" disabled>
                  Select Section
                </MenuItem>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {sectionId &&
          nameValuePairs.map((pair, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  value={pair.name}
                  onChange={(e) => handleChange(index, "name", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Value"
                  variant="outlined"
                  value={pair.value}
                  onChange={(e) => handleChange(index, "value", e.target.value)}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!sectionId}
          >
            Generate PDF
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateForSection;
