import React, { useState, useCallback } from "react";
import { Tooltip, Box, Modal, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import {
  Edit as EditIcon,
  ViewList as SectionsIcon,
  Quiz as QuestionsIcon,
  Delete as DeleteIcon,
  FileCopyOutlined as CloneIcon,
} from "@mui/icons-material";
import useStore from "hooks/useStore";
import Title from "components/ui/Title";
import Toolbar from "components/grid/Toolbar";
import PageLayout from "components/ui/PageLayout";
import ActionTooltip from "components/ui/ActionTooltip";
import ActionButtons from "components/ui/ActionButtons";

const withTemplateType = (templateType) => {
  return () => {
    const [selectedTemplateIdForClone, setSelectedTemplateIdForClone] =
      useState(false);
    const [selectedTemplateNameForClone, setSelectedTemplateNameForClone] =
      useState("");
    const defaultNewTemplateName = selectedTemplateNameForClone + " (copy)";
    const [newTemplateName, setNewTemplateName] = useState("");
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { data } = useResourceQuery([
      `templates?type=${templateType}`,
      ["templates", templateType],
    ]);
    const { mutateAsync: removeTemplate } = useResourceMutation(
      `templates`,
      MUTATION_TYPES.remove
    );
    const { mutate: cloneTemplate } = useResourceMutation([
      `templates/${selectedTemplateIdForClone}/clone`,
      "templates",
    ]);

    const setOpenModal = useStore((state) => state.setOpenModal);
    const columns = [
      { field: "name", headerName: "Name", flex: 1 },
      {
        field: "edit",
        headerName: "",
        width: 190,
        renderCell: (params) => (
          <>
            <Tooltip title="Clone template">
              <CloneIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedTemplateNameForClone(params.row.name);
                  setSelectedTemplateIdForClone(params.row.id);
                  setNewTemplateName(params.row.name + " (copy)");
                }}
              />
            </Tooltip>
            <ActionTooltip
              title="Edit template"
              IconComponent={EditIcon}
              onClick={() => {
                setOpenModal({ row: params.row }, "templates/Form");
              }}
            />
            <ActionTooltip
              title="Configure template sections"
              IconComponent={SectionsIcon}
              onClick={() => {
                navigate("/templates/" + params.row.id + "/sections", {
                  state: { templateType },
                });
              }}
            />
            <ActionTooltip
              title="Configure template questions"
              IconComponent={QuestionsIcon}
              onClick={() => {
                navigate("/templates/" + params.row.id + "/questions", {
                  state: { templateType },
                });
              }}
            />
            <ActionTooltip
              title="Delete template"
              IconComponent={DeleteIcon}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await confirm({
                    description:
                      "Are you sure you want to delete this template?",
                  });
                  removeTemplate(params.row.id);
                } catch {}
              }}
            />
          </>
        ),
      },
    ];

    const handleClose = () => {
      setSelectedTemplateIdForClone(false);
    };
    const handleClone = async () => {
      await cloneTemplate({
        newTemplateName: newTemplateName || defaultNewTemplateName,
      });
      handleClose();
    };

    return (
      <>
        {data && (
          <PageLayout title={`Manage ${templateType} templates`}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              slots={{
                toolbar: Toolbar,
              }}
              slotProps={{
                toolbar: {
                  toolTip: `Add ${templateType} template`,
                  onAddClick: () => {
                    setOpenModal({ type: templateType }, "templates/Form");
                  },
                },
              }}
              checkboxSelection
            />
          </PageLayout>
        )}
        <Modal open={!!selectedTemplateIdForClone} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 3,
              height: "270px",
            }}
          >
            <Title>Clone template</Title>
            <p>Clone template {selectedTemplateNameForClone}?</p>
            <TextField
              fullWidth
              label="New Template Name"
              variant="outlined"
              value={newTemplateName}
              placeholder={defaultNewTemplateName}
              style={{ marginBottom: "24px", marginTop: "18px" }}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <ActionButtons
              onCloseClick={handleClose}
              onActionClick={handleClone}
              actionText="Confirm"
              actionIcon={null}
            />
          </Box>
        </Modal>
      </>
    );
  };
};

export default withTemplateType;
