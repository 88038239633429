import { useMutation, useQueryClient } from "react-query";
import useContractwiseApi from "hooks/api/useContractwiseApi";

export const MUTATION_TYPES = {
  post: "post",
  patch: "patch",
  save: "save",
  remove: "remove",
};

function useResourceMutation(
  query,
  action = MUTATION_TYPES.save,
  apiConfig = {}
) {
  const queryPair = Array.isArray(query) ? query : [query, query];
  const resource = queryPair[0];
  const queryKey = queryPair[1];
  const queryClient = useQueryClient();
  const { post, patch, remove } = useContractwiseApi();
  const postMutation = (data, isFormData = false, requestConfig = {}) =>
    post(resource, apiConfig)(data, isFormData, requestConfig);
  const patchMutation = (id, data, isFormData = false, requestConfig = {}) =>
    patch(`${resource}/${id}`, apiConfig)(data, isFormData, requestConfig);

  const getMutationFunction = (action) => {
    switch (action) {
      case MUTATION_TYPES.post:
        return postMutation;
      case MUTATION_TYPES.patch:
        return patchMutation;
      case MUTATION_TYPES.save:
        return ({ id, ...rest }, isFormData = false, requestConfig = {}) =>
          id
            ? patchMutation(id, rest, isFormData, requestConfig)
            : postMutation(rest, isFormData, requestConfig);
      case MUTATION_TYPES.remove:
        return (id) => remove(`${resource}/${id}`, apiConfig)();
      default:
        throw new Error(`Invalid mutation action: ${action}`);
    }
  };

  const mutation = useMutation(getMutationFunction(action), {
    onError: (error) => {
      console.log(error);
    },
    onSuccess: async () => {
      console.log("Invalidating queries", queryKey);
      await queryClient.invalidateQueries([queryKey]);
      await queryClient.refetchQueries([queryKey]);
    },
  });

  return {
    ...mutation,
    data: mutation.data,
  };
}

export default useResourceMutation;
