import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useResourceQuery } from "hooks/useResourceQuery";
import {
  Upload as UploadIcon,
  Visibility as PreviewIcon,
} from "@mui/icons-material";
import useStore from "hooks/useStore";
import PageLayout from "components/ui/PageLayout";
import Breadcrumbs from "components/ui/Breadcrumbs";

const getTreeDataPath = (row) => row.hierarchy;

export default function TemplateSections() {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { state } = useLocation();
  const templateType = state?.templateType || "tender";
  const { data: template } = useResourceQuery(`templates/${templateId}`);
  const setOpenModal = useStore((state) => state.setOpenModal);
  const { data: rows } = useResourceQuery(`templates/${templateId}/sections`);

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") return "";
        if (!params.row.fileName) return "No file uploaded";
        return <span>{params.row.fileName}</span>;
      },
    },
    {
      field: "lastModified",
      headerName: "Last modified",
      width: 135,
      renderCell: (params) => {
        if (params.rowNode.type === "group") return "";
        if (!params.row.lastModified) return "";
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        };
        const date = new Date(parseInt(params.row.lastModified));
        const localTime = date.toLocaleString("en-ZA", options);
        return <span>{localTime}</span>;
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        if (params.rowNode.type === "group") return "";
        return (
          <>
            <PreviewIcon
              style={{ marginRight: "15px" }}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setOpenModal(
                  {
                    templateId,
                    row: params.row,
                  },
                  "template-sections/Preview"
                )
              }
            />
            <UploadIcon
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setOpenModal(
                  {
                    templateId,
                    row: params.row,
                  },
                  "template-sections/Form"
                )
              }
            />
          </>
        );
      },
    },
  ];

  const links = [
    {
      name: "Templates",
      href: null,
      onClick: () => navigate(`/${templateType}-templates`),
    },
  ];
  const breadcrumbs = (
    <Breadcrumbs links={links} currentPage={template?.name} />
  );

  return (
    <PageLayout title="Configure template sections" header={breadcrumbs}>
      {rows && (
        <DataGridPro
          treeData
          rows={rows}
          columns={columns}
          sx={{
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none !important",
            },
            flexGrow: 1,
          }}
          groupingColDef={{
            headerName: "Name",
            flex: 1,
            hideDescendantCount: true,
          }}
          getTreeDataPath={getTreeDataPath}
          defaultGroupingExpansionDepth={-1}
          hideFooter={true}
          hideFooterRowCount={true}
        />
      )}
    </PageLayout>
  );
}
