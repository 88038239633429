import * as React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";

function RedirectRoute() {
  const navigate = useNavigate();
  const isUserAdministrator = useStore((state) => state.isUserAdministrator());
  React.useEffect(() => {
    navigate(isUserAdministrator ? "/companies" : "/projects", {
      replace: true,
    });
  }, [isUserAdministrator, navigate]);

  return null;
}

export default RedirectRoute;
