import React from "react";
import { useSnackbar } from "notistack";
import { Button, IconButton, Box } from "@mui/material";
import { capitalizeFirstLetter } from "utils/stringUtils";

export const OnActionResponse = {
  InvalidForm: "InvalidForm",
};

const ActionButtons = ({
  isCreateMode = true,
  formNoun = null,
  onCloseClick,
  onActionClick,
  onSecondaryActionClick = () => {},
  actionText = null,
  actionIcon = null,
  isActionDisabled = false,
  actionSuccessMessage = null,
  actionFailureMessage = null,
  secondaryActionText = null,
  secondaryActionIcon = null,
  isSecondaryActionDisabled = false,
  showCancelButton = true,
  customIcons = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleActionClick = async () => {
    let error = null;
    let response = null;
    try {
      response = await onActionClick();
    } catch (error) {
      console.log("error", error);
      error = error;
    }
    const successMessage =
      actionSuccessMessage ||
      (formNoun &&
        `${capitalizeFirstLetter(formNoun)} ${
          isCreateMode ? "added" : "updated"
        } successfully`);
    const failureMessage =
      actionFailureMessage ||
      (response === OnActionResponse.InvalidForm
        ? "Invalid form entries"
        : formNoun &&
          `Error ${isCreateMode ? "adding" : "updating"} ${formNoun}`);
    if (!error && !response) {
      if (successMessage) {
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
      }
    } else {
      if (failureMessage) {
        enqueueSnackbar(failureMessage, {
          variant: !!error ? "error" : "warning",
        });
      }
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",

        flexDirection: "column",
        alignItems: "center",
        pb: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          display: "flex",
          justifyContent: showCancelButton ? "space-between" : "center",
          width: "100%",
          pr: 5,
          pl: 5,
        }}
      >
        {showCancelButton && (
          <Button variant="text" sx={{ mr: 2 }} onClick={onCloseClick}>
            Cancel
          </Button>
        )}
        <Box sx={{ display: "flex", gap: "10px" }}>
          {secondaryActionText && (
            <Button
              onClick={onSecondaryActionClick}
              variant="outlined"
              color="primary"
              disabled={isSecondaryActionDisabled}
            >
              {secondaryActionText}
              {secondaryActionIcon && (
                <IconButton
                  style={{
                    color: isSecondaryActionDisabled ? "light-grey" : "white",
                  }}
                  disabled={isSecondaryActionDisabled}
                  size="small"
                >
                  {secondaryActionIcon}
                </IconButton>
              )}
            </Button>
          )}
          <Button
            onClick={handleActionClick}
            variant="contained"
            color="primary"
            disabled={isActionDisabled}
          >
            {actionText || (isCreateMode ? "Add" : "Update")}
            {actionIcon && (
              <IconButton
                style={{ color: isActionDisabled ? "light-grey" : "white" }}
                disabled={isActionDisabled}
                size="small"
              >
                {actionIcon}
              </IconButton>
            )}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "27px",
          right: "0",
        }}
      >
        {/* Render custom icons */}
        {customIcons.map((Icon, index) => (
          <Box key={index} sx={{ ml: 1 }}>
            {Icon}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ActionButtons;
