// MainContent.js
import React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import Copyright from "components/ui/Copyright";

function MainContent() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        overflow: "hidden",
        position: "relative",
        mt: "64px",
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box flexGrow={1} overflow="hidden">
        <Outlet />
      </Box>
      <Copyright sx={{ position: "absolute", bottom: 7, left: 0, right: 0 }} />
    </Box>
  );
}

export default MainContent;
