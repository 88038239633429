import React from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import { Tooltip } from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import Toolbar from "components/grid/Toolbar";
import useStore from "hooks/useStore";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import PageLayout from "components/ui/PageLayout";
import Breadcrumbs from "components/ui/Breadcrumbs";

const SubProjectsList = () => {
  const confirm = useConfirm();
  const { id: projectId } = useParams();
  const { data: subProjects } = useResourceQuery([
    `projects/${projectId}/subprojects`,
    ["subprojects", projectId],
  ]);
  const { data: projectData } = useResourceQuery(`/projects/${projectId}`);
  const { mutateAsync: removeSubProject } = useResourceMutation(
    "subprojects",
    MUTATION_TYPES.remove
  );
  const setOpenModal = useStore((state) => state.setOpenModal);

  const columns = [
    {
      field: "contractualReferenceNumber",
      headerName: "Contractual Reference Number",
      flex: 1,
    },
    {
      field: "contractualDescription",
      headerName: "Contractual Description",
      flex: 1,
    },
    ...(subProjects && subProjects.some((subProject) => subProject.templateId)
      ? [
          {
            field: "templateName",
            headerName: "Template Name",
            flex: 1,
          },
          {
            field: "reviewStatus",
            headerName: "Review Status",
            flex: 1,
            valueGetter: (params) => {
              const {
                reviewStatus,
                creatorFirstName,
                creatorLastName,
                reviewerFirstName,
                reviewerLastName,
              } = params.row;
              if (reviewStatus === "Submitted") {
                return `${reviewStatus} by ${creatorFirstName || "Unknown"} ${
                  creatorLastName || ""
                }`;
              }
              if (reviewStatus === "Approved" || reviewStatus === "Rejected") {
                return `${reviewStatus} by ${reviewerFirstName || "Unknown"} ${
                  reviewerLastName || ""
                }`;
              }
              return reviewStatus;
            },
          },
        ]
      : []),
    {
      field: "edit",
      headerName: "Actions",
      align: "right",
      width: 120,
      renderCell: (params) => (
        <>
          {["None", "Approved"].includes(params.row.reviewStatus) && (
            <Tooltip title="Preview Contract">
              <VisibilityIcon
                sx={{ cursor: "pointer", ml: 1.5 }}
                onClick={() => {
                  setOpenModal(
                    {
                      contractId: params.row.contractId,
                    },
                    "contracts/Preview"
                  );
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Edit sub-project">
            <EditIcon
              sx={{ cursor: "pointer", ml: 1.5 }}
              onClick={(e) => {
                e.stopPropagation();
                const {
                  id,
                  contractualReferenceNumber,
                  contractualDescription,
                } = params.row;
                setOpenModal({
                  row: {
                    id,
                    contractualDescription,
                    contractualReferenceNumber,
                  },
                  projectId,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Delete sub-project">
            <DeleteIcon
              sx={{ cursor: "pointer", ml: 1.5 }}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  await confirm({
                    description:
                      "Are you sure you want to delete this sub-project?",
                  });
                  removeSubProject(params.row.id);
                } catch {}
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const links = [
    {
      name: "Projects",
      href: "/projects",
      onClick: null,
    },
  ];
  const breadcrumbs = (
    <Breadcrumbs
      links={links}
      currentPage={projectData?.contractualReferenceNumber ?? ""}
    />
  );

  return (
    <>
      {subProjects && (
        <PageLayout title="Manage sub-projects" header={breadcrumbs}>
          <DataGrid
            rows={subProjects}
            columns={columns}
            pageSize={5}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{
              toolbar: {
                toolTip: "Add subproject",
                onAddClick: () => {
                  setOpenModal({ projectId });
                },
              },
            }}
          />
        </PageLayout>
      )}
    </>
  );
};

export default SubProjectsList;
