import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import PrivateRoute from "./components/routing/PrivateRoute";
import DashboardLayout from "./layouts/Dashboard/Dashboard";
import MinimalLayout from "layouts/Minimal/Minimal";
import Login from "./pages/login/Login";
import ForgotPassword from "pages/forgot-password/ForgotPassword";
import ResetPassword from "pages/reset-password/ResetPassword";
import CompaniesList from "./pages/companies/List";
import TendersList from "./pages/contracts/ListTenders";
import ContractsList from "./pages/contracts/ListContracts";
import OthersList from "./pages/contracts/ListOthers";
import ReviewsList from "./pages/reviews/List";
import ManageAdminUsers from "pages/administrators/List";
import UsersList from "./pages/users/List";
import TemplatesListTender from "./pages/templates/TemplatesListTender";
import TemplatesListContract from "./pages/templates/TemplatesListContract";
import TemplatesListOther from "./pages/templates/TemplatesListOther";
import TemplateSections from "pages/template-sections/TemplateSections";
import TemplateQuestions from "pages/template-questions/TemplateQuestions";
import CreateForSection from "pages/pdf/CreateForSection";
import CreateForTemplate from "pages/pdf/CreateForTemplate";
import ProjectsList from "pages/projects/List";
import SubProjectsList from "pages/subprojects/List";
import DraggableList from "pages/testing/DraggableList";
import TreeView from "pages/testing/TreeView";
import ReviewContract from "pages/reviews/Review";
import RedirectRoute from "components/routing/RedirectRoute";

const queryClient = new QueryClient();

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <ConfirmProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<MinimalLayout />}>
                  <Route path="login" element={<Login />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="contract-review" element={<ReviewContract />} />
                </Route>

                <Route path="/*" element={<PrivateRoute />}>
                  <Route element={<DashboardLayout />}>
                    <Route
                      path="administrators"
                      element={<ManageAdminUsers />}
                    />
                    <Route path="companies" element={<CompaniesList />} />
                    <Route path="companies/:id/users" element={<UsersList />} />
                    <Route
                      path="tender-templates"
                      element={<TemplatesListTender />}
                    />
                    <Route
                      path="contract-templates"
                      element={<TemplatesListContract />}
                    />
                    <Route
                      path="other-templates"
                      element={<TemplatesListOther />}
                    />
                    <Route
                      path="templates/:templateId/sections"
                      element={<TemplateSections />}
                    />
                    <Route
                      path="templates/:templateId/questions"
                      element={<TemplateQuestions />}
                    />
                    <Route path="projects" element={<ProjectsList />} />
                    <Route
                      path="projects/:id/subprojects"
                      element={<SubProjectsList />}
                    />
                    <Route path="tenders" element={<TendersList />} />
                    <Route path="contracts" element={<ContractsList />} />
                    <Route path="others" element={<OthersList />} />
                    <Route path="reviews" element={<ReviewsList />} />
                    <Route
                      path="pdf/create-for-section"
                      element={
                        <CreateForSection templateId="1" sectionId="1" />
                      }
                    />
                    <Route
                      path="pdf/create-for-template"
                      element={<CreateForTemplate templateId="1" />}
                    />
                    <Route
                      path="testing/draggable-list"
                      element={<DraggableList />}
                    />
                    <Route path="testing/treeview" element={<TreeView />} />
                    <Route
                      path="*"
                      element={<RedirectRoute />}
                      replace={true}
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ConfirmProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
