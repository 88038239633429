import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Title from "components/ui/Title";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";

function CreateForTemplate() {
  const { data: templates } = useResourceQuery("templates");
  const [templateId, setTemplateId] = useState("");
  const [nameValuePairs, setNameValuePairs] = useState([
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
    { name: "", value: "" },
  ]);
  const { mutateAsync: createMergedPdf } = useResourceMutation(
    `templates/${templateId}/generate-pdf`,
    MUTATION_TYPES.post,
    { responseType: "blob" }
  );

  const handleChange = (index, field, value) => {
    const updatedNameValuePairs = [...nameValuePairs];
    updatedNameValuePairs[index][field] = value;
    setNameValuePairs(updatedNameValuePairs);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = nameValuePairs.reduce((acc, pair) => {
      if (pair.name) {
        acc[pair.name] = pair.value;
      }
      return acc;
    }, {});

    const pdfData = await createMergedPdf(data);

    if (pdfData) {
      const url = URL.createObjectURL(pdfData);
      const link = document.createElement("a");
      link.href = url;
      link.download = "merged_pdf.pdf";
      link.click();
    } else {
      alert("Error generating PDF");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Generate merged PDF for Template</Title>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="template-select-label">Select Template</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              label="Select Template"
            >
              {templates &&
                templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {templateId &&
          nameValuePairs.map((pair, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  value={pair.name}
                  onChange={(e) => handleChange(index, "name", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Value"
                  variant="outlined"
                  value={pair.value}
                  onChange={(e) => handleChange(index, "value", e.target.value)}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!templateId}
          >
            Generate merged PDF
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateForTemplate;
