import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';

function Title(props) {
  const { children, onBackClick } = props;
  const theme = useTheme();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {onBackClick && (
        <ArrowBackIcon
          onClick={onBackClick}
          sx={{ color: theme.palette.primary.main, cursor: 'pointer', marginRight: '0.40em', marginBottom: "0.35em" }}
        />
      )}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {children}
      </Typography>
    </div>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  onBackClick: PropTypes.func,
};

export default Title;