import useApi from "./useApi";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const DEFAULT_HEADERS = { "Content-Type": "application/json" };

const useContractwiseApi = (
  headers = DEFAULT_HEADERS,
  queryParameters = []
) => {
  return {
    ...useApi({
      baseURL: apiBaseUrl,
      queryParameters,
      headers,
    }),
  };
};

export default useContractwiseApi;
