import React from "react";
import { TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  "& .MuiTreeItem-content": {
    padding: theme.spacing(1),
    borderWidth: 0,
  },
  "& .MuiTreeItem-label": {
    display: "flex",
  },
  "& .MuiTreeItem-iconContainer": {
    marginRight: theme.spacing(1),
  },
  "& .MuiTreeItem-group > .MuiTreeItem-content": {
    borderLeft: "none",
  },
  "& .MuiTreeItem-group .MuiTreeItem-label": {
    paddingLeft: theme.spacing(2),
  },
}));

const StyledTreeView = styled(TreeView)({
  "& .MuiTreeItem-group": {
    marginLeft: 0,
  },
});

const MyTree = () => {
  return (
    <Paper elevation={3} style={{ padding: "16px", width: "300px" }}>
      <StyledTreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        <StyledTreeItem nodeId="1" label="Parent 1">
          <StyledTreeItem nodeId="2" label="Child 1" />
          <StyledTreeItem nodeId="3" label="Child 2" />
        </StyledTreeItem>
        <StyledTreeItem nodeId="4" label="Parent 2" />
      </StyledTreeView>
    </Paper>
  );
};

export default MyTree;
