import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import Toolbar from "components/grid/Toolbar";
import PageLayout from "components/ui/PageLayout";
import ActionTooltip from "components/ui/ActionTooltip";
import useStore from "hooks/useStore";

const ManageAdminUsers = () => {
  const confirm = useConfirm();
  const { data: adminUserList } = useResourceQuery(["users/admins", "admins"]);
  const setOpenModal = useStore((state) => state.setOpenModal);
  const { mutateAsync: removeUser } = useResourceMutation(
    ["users", "admins"],
    MUTATION_TYPES.remove
  );

  const columns = [
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 120,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <>
          <ActionTooltip
            title="Edit admin user"
            IconComponent={EditIcon}
            onClick={(e) => {
              setOpenModal({ row: params.row });
            }}
          />
          <ActionTooltip
            title="Delete admin user"
            IconComponent={DeleteIcon}
            onClick={async (e) => {
              try {
                await confirm({
                  description:
                    "Are you sure you want to delete this administrator?",
                });
                removeUser(params.row.id);
              } catch {}
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {adminUserList && (
        <PageLayout title="Manage administrators">
          <DataGrid
            rows={adminUserList}
            columns={columns}
            pageSize={5}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{
              toolbar: {
                toolTip: "Add user",
                onAddClick: () => {
                  setOpenModal();
                },
              },
            }}
            checkboxSelection
          />
        </PageLayout>
      )}
    </>
  );
};

export default ManageAdminUsers;
