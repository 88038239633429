import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { useResourceQuery } from "hooks/useResourceQuery";
import {
  PostAdd as LibraryAddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import useStore from "hooks/useStore";
import PageLayout from "components/ui/PageLayout";
import ActionTooltip from "components/ui/ActionTooltip";
import Toolbar from "components/grid/Toolbar";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";

const CompaniesList = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { data } = useResourceQuery("companies");
  const { mutateAsync: removeCompany } = useResourceMutation(
    "companies",
    MUTATION_TYPES.remove
  );
  const setOpenModal = useStore((state) => state.setOpenModal);
  const columns = [
    { field: "registeredName", headerName: "Registered Name", width: 220 },
    { field: "tradingName", headerName: "Trading Name", width: 220 },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      width: 160,
    },
    { field: "address", headerName: "Address", flex: 1 },
    {
      field: "edit",
      headerName: "Actions",
      align: "right",
      width: 140,
      renderCell: (params) => (
        <>
          <ActionTooltip
            title="Assign templates"
            IconComponent={LibraryAddIcon}
            onClick={(e) => {
              setOpenModal(
                { companyId: params.row.id },
                "companies/AssignTemplates"
              );
            }}
          />
          <ActionTooltip
            title="Manage Users"
            IconComponent={PeopleIcon}
            onClick={(e) => {
              navigate(`/companies/${params.row.id}/users`);
            }}
          />
          <ActionTooltip
            title="Edit company"
            IconComponent={EditIcon}
            onClick={(e) => {
              setOpenModal({ row: params.row });
            }}
          />
          <ActionTooltip
            title="Delete company"
            IconComponent={DeleteIcon}
            onClick={async (e) => {
              try {
                await confirm({
                  description: "Are you sure you want to delete this company?",
                });
                removeCompany(params.row.id);
              } catch {}
            }}
          />
        </>
      ),
    },
  ];
  return (
    <>
      {data && (
        <PageLayout title="Manage companies">
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{
              toolbar: {
                toolTip: "Add company",
                onAddClick: () => {
                  setOpenModal();
                },
              },
            }}
            checkboxSelection
          />
        </PageLayout>
      )}
    </>
  );
};

export default CompaniesList;
