import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import useStore from "hooks/useStore";
import Toolbar from "components/grid/Toolbar";
import ActionTooltip from "components/ui/ActionTooltip";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";
import PageLayout from "components/ui/PageLayout";
import Breadcrumbs from "components/ui/Breadcrumbs";

export default function TemplateQuestions() {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { state } = useLocation();
  const templateType = state?.templateType || "tender";
  const confirm = useConfirm();
  const { data: template } = useResourceQuery(`templates/${templateId}`);
  const { data: questionsData } = useResourceQuery([
    `templates/${templateId}/questions`,
    ["template-questions", templateId],
  ]);
  const { data: fieldTypesData } = useResourceQuery("field-types");

  const [fieldTypes, setFieldTypes] = useState({});

  // When fieldTypesData updates, convert it to a lookup object
  useEffect(() => {
    if (fieldTypesData) {
      const newFieldTypes = {};
      for (const type of fieldTypesData) {
        newFieldTypes[type.id] = type.type;
      }
      setFieldTypes(newFieldTypes);
    }
  }, [fieldTypesData]);

  const { mutateAsync: removeQuestion } = useResourceMutation(
    `template-questions`,
    MUTATION_TYPES.remove
  );
  const setOpenModal = useStore((state) => state.setOpenModal);

  const columns = [
    {
      field: "id",
      headerName: "Macro",
      valueGetter: (params, x) => `Q${params.row.rowIndex}`,
      width: 100,
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1,
    },
    {
      field: "information",
      headerName: "Information",
      hidden: true,
      flex: 1,
    },
    {
      field: "fieldTypeId",
      headerName: "Field Type",
      valueGetter: (params) => fieldTypes[params.value],
      width: 120,
    },
    {
      headerName: "Actions",
      width: 120,
      disableExport: true,
      hideable: false,
      renderCell: (params) => (
        <>
          <ActionTooltip
            title="Edit question"
            IconComponent={EditIcon}
            onClick={(e) => {
              const { rowIndex, ...row } = params.row;
              e.stopPropagation();
              setOpenModal({ row, templateId }, "template-questions/Form");
            }}
          />
          <ActionTooltip
            title="Delete question"
            IconComponent={DeleteIcon}
            onClick={async (e) => {
              e.stopPropagation();
              try {
                await confirm({
                  description: "Are you sure you want to delete this question?",
                });
                removeQuestion(params.row.id);
              } catch {}
            }}
          />
        </>
      ),
    },
  ];

  const links = [
    {
      name: "Templates",
      href: null,
      onClick: () => navigate(`/${templateType}-templates`),
    },
  ];
  const breadcrumbs = (
    <Breadcrumbs links={links} currentPage={template?.name} />
  );

  return (
    <>
      {questionsData && fieldTypesData && (
        <PageLayout title="Manage template questions" header={breadcrumbs}>
          <DataGrid
            rows={questionsData.map((question, index) => ({
              ...question,
              rowIndex: index + 1,
            }))}
            columns={columns}
            pageSize={5}
            slots={{
              toolbar: Toolbar,
            }}
            slotProps={{
              toolbar: {
                toolTip: "Add question",
                onAddClick: () => {
                  setOpenModal({ templateId }, "template-questions/Form");
                },
                csvOptions: { allColumns: true },
              },
            }}
            checkboxSelection
          />
        </PageLayout>
      )}
    </>
  );
}
