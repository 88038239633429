import { Grid, Box, useTheme } from "@mui/material";
import Title from "./Title";
import useStore from "hooks/useStore"; // Import your store

const PageLayout = ({ children, title, header, boxProps }) => {
  const drawerOpen = useStore((state) => state.drawerOpen); // Get the drawerOpen state
  const theme = useTheme();
  const isSmUp = window.innerWidth >= theme.breakpoints.values.sm;

  const baseOffset = 154; // Header 88 + Copyright 27 + Title 39
  const headerOffset = header ? 24 : 0; // Additional offset if header is present
  const totalOffset = baseOffset + headerOffset;

  const drawerWidth = drawerOpen ? 240 : isSmUp ? 72 : 56;
  const totalWidth = `calc(100vw - ${drawerWidth + 40}px)`; // 40 px padding left and right

  const defaultBoxProps = {
    p: 2,
    borderRadius: 1,
    boxShadow: 1,
    bgcolor: "background.paper",
    height: `calc(100vh - ${totalOffset}px)`,
    width: totalWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };

  const mergedBoxProps = { ...defaultBoxProps, ...boxProps };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="start"
      justifyContent="start"
      sx={{
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
    >
      {header && <div>{header}</div>}
      <Title>{title}</Title>
      <Box sx={mergedBoxProps}>{children}</Box>
    </Grid>
  );
};

export default PageLayout;
