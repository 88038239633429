import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Backdrop,
  Box,
  Slide,
  Fade,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Check as ApproveIcon,
  Close as RejectIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import useStore from "hooks/useStore";
import Title from "components/ui/Title";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation from "hooks/useResourceMutation";
import ApproveContractModal from "./ApproveContractModal";
import RejectContractModal from "./RejectContractModal";
import ModalDrawer from "layouts/Dashboard/ModalDrawer";

const ReviewContract = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setOpenModal } = useStore();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const [isLoading, setIsLoading] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [contractStatus, setContractStatus] = useState("pending");

  const { data, error } = useResourceQuery([
    `contracts/review/${token}`,
    "contract",
  ]);
  const { mutateAsync: approveContract } = useResourceMutation([
    "contracts/approve/token",
    "contracts",
  ]);
  const { mutateAsync: rejectContract } = useResourceMutation([
    "contracts/reject/token",
    "contracts",
  ]);

  useEffect(() => {
    if (data) {
      setContractData(data);
    }
  }, [data]);

  const handlePreviewClick = () => {
    setOpenModal(
      {
        contractId: contractData.contractId,
        token: token,
      },
      "contracts/Preview"
    );
  };

  const handleApproveClick = () => {
    setApproveModalOpen(true);
  };

  const handleApproveConfirm = async () => {
    setApproveModalOpen(false);
    setIsLoading(true);
    try {
      await approveContract({
        contractId: contractData.contractId,
        reviewId: contractData.lastReviewId,
        token: token,
      });
      setContractStatus("approved");
      enqueueSnackbar("Contract approved successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error approving contract: " + error.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectClick = () => {
    setRejectModalOpen(true);
  };

  const handleRejectConfirm = async (comments) => {
    setRejectModalOpen(false);
    setIsLoading(true);
    try {
      await rejectContract({
        contractId: contractData.contractId,
        reviewId: contractData.lastReviewId,
        reviewComments: comments,
        token: token,
      });
      setContractStatus("rejected");
      enqueueSnackbar("Contract rejected successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error rejecting contract: " + error.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!!error) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "340px",
          width: "500px",
        }}
      >
        <p style={{ textAlign: "center", marginBottom: 60 }}>
          Something went wrong when loading the review screen; either the review
          has already been done or the link you followed is expired. Please
          login in the the application to continue.
        </p>
        <Button variant="contained" onClick={() => navigate("/login")}>
          Go to login
        </Button>
      </div>
    );
  }

  if (!contractData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "340px",
          width: "500px",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }}>
          Loading contract review
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" sx={{ marginTop: 10 }} />
      </Backdrop>
      <Box sx={{ width: "500px" }}>
        <Fade
          in={contractStatus === "pending"}
          appear={false}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Title>Review contract/tender</Title>
            <Box mb={2} display="flex" flexDirection="column">
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Project Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {contractData.projectName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Subproject Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {contractData.subprojectName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Template
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {contractData.templateName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Created On
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {format(
                      new Date(contractData.createdOn),
                      "dd/MM/yyyy HH:mm"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Created By
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {`${contractData.creatorFirstName} ${contractData.creatorLastName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    Review Number
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {contractData.reviewCount}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" justifyContent="center" gap={2}>
              <Button
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={handlePreviewClick}
              >
                Preview
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<RejectIcon />}
                onClick={handleRejectClick}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<ApproveIcon />}
                onClick={handleApproveClick}
              >
                Approve
              </Button>
            </Box>
          </div>
        </Fade>
        <Fade
          appear={true}
          in={["approved", "rejected"].includes(contractStatus)}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "240px",
              width: "500px",
            }}
          >
            <p style={{ textAlign: "center", marginBottom: 60 }}>
              The contract has now been {contractStatus}.
            </p>
            <Button variant="contained" onClick={() => navigate("/login")}>
              Go to login
            </Button>
          </div>
        </Fade>
      </Box>
      <ApproveContractModal
        isOpen={isApproveModalOpen}
        onClose={() => setApproveModalOpen(false)}
        onConfirm={handleApproveConfirm}
        {...contractData}
      />
      <RejectContractModal
        isOpen={isRejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        onConfirm={handleRejectConfirm}
        {...contractData}
      />
      <ModalDrawer />
    </>
  );
};

export default ReviewContract;
