// CompanySelect.js
import React, { useState, useEffect } from "react";
import Select from "react-select";
import useStore from "hooks/useStore";
import { useResourceQuery } from "hooks/useResourceQuery";

function CompanySelect({ open }) {
  const { data: companyList } = useResourceQuery("companies");
  const [selectOptions, setSelectOptions] = useState([]);
  const selectedCompany = useStore((state) => state.selectedCompany);
  const setSelectedCompany = useStore((state) => state.setSelectedCompany);
  const noSelection = { value: null, label: "No company selected" };

  const handleCompanyChange = (selectedOption) => {
    setSelectedCompany(selectedOption.value);
  };

  useEffect(() => {
    if (companyList) {
      const options = companyList.map((company) => ({
        value: company.id,
        label: company.tradingName,
      }));
      setSelectOptions([noSelection, ...options]);
    }
  }, [companyList]);

  useEffect(() => {
    if (selectOptions.length > 0 && selectedCompany) {
      const defaultOption = selectOptions.find(
        (option) => option.value === selectedCompany
      );
      setSelectedCompany(defaultOption.value);
    }
  }, [selectOptions, selectedCompany]);

  if (!selectOptions) return null;

  return (
    <Select
      value={
        selectOptions.find((option) => option.value === selectedCompany) ||
        noSelection
      }
      options={selectOptions}
      onChange={handleCompanyChange}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: 350,
          maxHeight: 350,
          overflow: "auto",
          marginLeft: open ? "240px" : "0px",
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          color: state.isSelected ? "white" : "black",
          backgroundColor: state.isSelected ? "rgb(0,107,200)" : "white",
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: "14px",
        }),
        control: (provided) => ({
          ...provided,
          width: "350px",
          marginLeft: open ? "240px" : "0px",
          borderRadius: 0,
        }),
      }}
    />
  );
}

export default CompanySelect;
