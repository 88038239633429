import React, { useState, useEffect, Suspense } from "react";
import { useLocation } from "react-router-dom";
import {
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import useStore from "hooks/useStore";

const ModalDrawer = () => {
  const [requiresConfirmation, setRequiresConfirmation] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const openModal = useStore((state) => state.openModal);
  const clearModal = useStore((state) => state.clearModal);
  const location = useLocation();
  const pagePath =
    openModal?.pagePath || location.pathname.split("/").pop() + "/Form";

  const ModalContents = openModal
    ? React.lazy(() => import(`../../pages/${pagePath}.js`))
    : null;

  const handleDrawerClose = () => {
    if (requiresConfirmation) {
      setShowConfirmDialog(true);
    } else {
      clearModal();
    }
  };

  const handleCloseConfirmDialog = (confirm) => {
    setShowConfirmDialog(false);
    if (confirm) {
      clearModal();
    }
  };

  const renderModalContents = () => {
    if (!ModalContents) return null;
    return (
      <ModalContents
        close={clearModal}
        {...openModal?.properties}
        setRequiresConfirmation={setRequiresConfirmation}
      />
    );
  };

  useEffect(() => {
    setRequiresConfirmation(false);
  }, [openModal]);

  return (
    <>
      <Drawer
        anchor="right"
        open={Boolean(openModal)}
        onClose={handleDrawerClose}
        style={{ zIndex: 1300, minWidth: "400px" }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          {renderModalContents()}
        </Suspense>
      </Drawer>
      <Dialog
        open={showConfirmDialog}
        onClose={() => handleCloseConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Close"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close this form? Any unsaved changes will
            be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseConfirmDialog(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleCloseConfirmDialog(true)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDrawer;
