import jwt_decode from "jwt-decode";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const MODES = {
  light: "light",
  dark: "dark",
  system: "system",
};

const useStore = create(
  persist(
    (set, get) => ({
      // Drawer
      drawerOpen: true,
      setDrawerOpen: (open) => set({ drawerOpen: open }),
      // Light/Dark mode
      mode: MODES.light,
      setMode: (mode) => set({ mode }),
      // Open dialogs
      openModal: null,
      clearModal: () => set({ openModal: null }),
      setOpenModal: (properties, pagePath = null) => {
        set({ openModal: { properties, pagePath } });
      },
      // Login
      loginDetails: null,
      setLoginDetails: (username, accessToken, refreshToken) => {
        const user = jwt_decode(accessToken);
        if (user.permissions.includes("administrator")) {
          get().clearSelectedCompany();
        } else {
          get().setSelectedCompany(user.companies[0]?.id);
        }
        set({
          loginDetails: {
            username,
            accessToken,
            refreshToken,
            user,
          },
        });
      },
      clearLoginDetails: () => set({ loginDetails: null }),
      getAccessToken: () => get()?.loginDetails?.accessToken,
      setAccessToken: (accessToken) => {
        set({ loginDetails: { ...get()?.loginDetails, accessToken } });
      },
      getRefreshToken: () => get()?.loginDetails?.refreshToken,
      setTokens: (accessToken, refreshToken) => {
        set({
          loginDetails: {
            ...get()?.loginDetails,
            accessToken,
            refreshToken,
          },
        });
      },
      isUserAuthenticated: () => !!get().loginDetails?.accessToken,
      isUserAdministrator: () => {
        return get().loginDetails?.user?.permissions?.includes("administrator");
      },

      // Active company
      selectedCompany: null,
      clearSelectedCompany: () => set({ selectedCompany: null }),
      setSelectedCompany: (companyId) => set({ selectedCompany: companyId }),
      getActiveCompany: () => {
        return get().selectedCompany; //get().loginDetails?.user.companyId ||
      },
      hasContractsDirectorPermissionsInActiveCompany: () => {
        const activeCompany = get().getActiveCompany();
        return get().loginDetails?.user?.companyPermissions?.[
          activeCompany
        ]?.includes("contracts-director");
      },
      hasContractsCompilerPermissionsInActiveCompany: () => {
        const activeCompany = get().getActiveCompany();
        return get().loginDetails?.user?.companyPermissions?.[
          activeCompany
        ]?.includes("contracts-compiler");
      },
      hasContractsApproverPermissionsInActiveCompany: () => {
        const activeCompany = get().getActiveCompany();
        return get().loginDetails?.user?.companyPermissions?.[
          activeCompany
        ]?.includes("contracts-approver");
      },
    }),
    {
      name: "contractwise",
      getStorage: () => localStorage,
    }
  )
);

export default useStore;
