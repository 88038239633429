import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const CustomBreadcrumbs = ({ links, currentPage }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((link, index) => (
        <Link
          key={index}
          color="inherit"
          href={link.href}
          onClick={link.onClick}
        >
          {link.name}
        </Link>
      ))}
      <Typography color="textPrimary">{currentPage}</Typography>
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
