import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Box,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import useResourceMutation, { MUTATION_TYPES } from "hooks/useResourceMutation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetFailed, setResetFailed] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const { mutateAsync: requestReset } = useResourceMutation(
    "auth/request-reset",
    MUTATION_TYPES.post
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    requestReset(
      { email },
      {
        onSuccess: () => {
          setResetFailed(false);
          setResetSuccess(true);
        },
        onError: () => {
          setResetFailed(true);
          setResetSuccess(false);
        },
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Forgot Password
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {resetFailed && (
        <FormHelperText error>
          Reset password request failed. Please try again.
        </FormHelperText>
      )}
      <Button
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        fullWidth
      >
        Submit
      </Button>
      <Link
        href="/login"
        variant="body2"
        style={{ float: "right", marginTop: "10px" }}
      >
        Back to Login
      </Link>
      <Snackbar
        open={resetSuccess}
        autoHideDuration={6000}
        onClose={() => setResetSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" style={{ minWidth: "500px" }}>
          If the entered email is associated with a user account, a password
          reset link will be sent.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;
