import { Navigate, Outlet } from "react-router-dom";
import useStore from "hooks/useStore";
import jwt_decode from "jwt-decode";

const PrivateRoute = () => {
  const loginDetails = useStore((state) => state.loginDetails);
  const isUserAuthenticated = useStore((state) => state.isUserAuthenticated());

  let isRefreshTokenExpired = false;

  // Decode the refresh token only if it exists
  if (loginDetails?.refreshToken) {
    const decodedRefreshToken = jwt_decode(loginDetails.refreshToken);
    isRefreshTokenExpired = Date.now() / 1000 >= decodedRefreshToken.exp;
  }

  // If the user isn't authenticated or the refresh token has expired (or doesn't exist), redirect to the login page
  if (!isUserAuthenticated || isRefreshTokenExpired) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
