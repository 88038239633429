import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useResourceQuery } from "hooks/useResourceQuery";
import useResourceMutation from "hooks/useResourceMutation";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import useStore from "hooks/useStore";
import Toolbar from "components/grid/Toolbar";
import {
  Visibility as VisibilityIcon,
  Check as ApproveIcon,
  Close as RejectIcon,
} from "@mui/icons-material";
import RejectContractModal from "./RejectContractModal";
import ApproveContractModal from "./ApproveContractModal";
import PageLayout from "components/ui/PageLayout";
import ActionTooltip from "components/ui/ActionTooltip";

const ReviewsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedCompany = useStore((state) => state.selectedCompany);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectData, setRejectData] = useState(null);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [approveData, setApproveData] = useState(null);
  const { data } = useResourceQuery([
    `companies/${selectedCompany}/contracts?reviewStatus=Submitted&permissionContext=review`,
    ["contracts", selectedCompany, "Submitted"],
  ]);
  const { mutateAsync: approveContract } = useResourceMutation([
    "contracts/approve",
    "contracts",
  ]);
  const { mutateAsync: rejectContract } = useResourceMutation([
    "contracts/reject",
    "contracts",
  ]);

  const setOpenModal = useStore((state) => state.setOpenModal);

  const handleApproveClick = (row) => {
    setApproveData(row);
    setApproveModalOpen(true);
  };

  const handleApproveConfirm = async () => {
    setApproveModalOpen(false);
    try {
      await approveContract({
        contractId: approveData.contractId,
        reviewId: approveData.lastReviewId,
      });
      enqueueSnackbar("Contract approved successfully", { variant: "success" });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Error approving contract: " + error.message, {
        variant: "error",
      });
    }
  };

  const handleRejectClick = (row) => {
    setRejectData(row);
    setRejectModalOpen(true);
  };

  const handleRejectConfirm = async (comments) => {
    setRejectModalOpen(false);
    try {
      await rejectContract({
        contractId: rejectData.contractId,
        reviewId: rejectData.lastReviewId,
        reviewComments: comments,
      });
      enqueueSnackbar("Contract rejected successfully", { variant: "success" });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Error rejecting contract: " + error.message, {
        variant: "error",
      });
    }
  };

  const columns = [
    { field: "projectName", headerName: "Project Name", flex: 1 },
    { field: "subprojectName", headerName: "Subproject Name", flex: 1 },
    { field: "templateName", headerName: "Template", flex: 1 },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params.row.createdOn);
        return format(date, "dd/MM/yyyy HH:mm");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.creatorFirstName} ${params.row.creatorLastName}`;
      },
    },
    { field: "templateType", headerName: "Type", flex: 1 },
    { field: "reviewCount", headerName: "Review Count", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      align: "right",
      width: 150,
      renderCell: (params) => (
        <>
          <ActionTooltip
            title="Preview Contract"
            IconComponent={VisibilityIcon}
            onClick={() => {
              setOpenModal(
                {
                  contractId: params.row.contractId,
                },
                "contracts/Preview"
              );
            }}
          />
          <ActionTooltip
            title="Reject Contract"
            IconComponent={RejectIcon}
            onClick={() => {
              handleRejectClick(params.row);
            }}
          />
          <ActionTooltip
            title="Approve Contract"
            IconComponent={ApproveIcon}
            onClick={() => {
              handleApproveClick(params.row);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {data && (
        <PageLayout title="Review contracts and tenders">
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            slots={{ toolbar: Toolbar }}
            slotProps={{
              toolbar: {
                showAddButton: false,
              },
            }}
            getRowId={(row) => row.contractId}
            checkboxSelection
          />
        </PageLayout>
      )}
      <RejectContractModal
        isOpen={isRejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        onConfirm={handleRejectConfirm}
        {...rejectData}
      />
      <ApproveContractModal
        isOpen={isApproveModalOpen}
        onClose={() => setApproveModalOpen(false)}
        onConfirm={handleApproveConfirm}
        {...approveData}
      />
    </>
  );
};

export default ReviewsList;
