// Dashboard.js
import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import useLogout from "./useLogout";
import Header from "./Header";
import Drawer from "./Drawer";
import MainContent from "./MainContent";
import ModalDrawer from "./ModalDrawer";
import useStore from "hooks/useStore";

const mdTheme = createTheme();

function Dashboard() {
  const drawerOpen = useStore((state) => state.drawerOpen);
  const setDrawerOpen = useStore((state) => state.setDrawerOpen);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleLogout = useLogout();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Header
          open={drawerOpen}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleLogout={handleLogout}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
        <MainContent />
      </Box>
      <ModalDrawer />
    </ThemeProvider>
  );
}

export default Dashboard;
