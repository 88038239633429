import { useQuery } from "react-query";
import useContractwiseApi from "hooks/api/useContractwiseApi";

export const useResourceQuery = (
  query,
  enabled = true,
  apiConfig = {},
  options = {}
) => {
  const { get } = useContractwiseApi();
  const queryPair = Array.isArray(query) ? query : [query, query];
  const fetchFunction = () => {
    // Only make the API call if enabled is true
    if (enabled) {
      return get(queryPair[0], apiConfig);
    } else {
      // Return a resolved promise directly to bypass the fetch
      return () => Promise.resolve([]);
    }
  };
  return useQuery(queryPair[1], fetchFunction(), {
    ...options,
    enabled,
  });
};
