// useLogout.js
import { useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { useState } from "react";

function useLogout() {
  const clearLoginDetails = useStore((state) => state.clearLoginDetails);
  const navigate = useNavigate();

  const handleLogout = () => {
    clearLoginDetails();
    navigate("/login");
  };

  return handleLogout;
}

export default useLogout;
